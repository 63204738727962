import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import WebinarCard from './WebinarCard';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WebinarListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 45px;
  gap: 48px;
  width: fit-content;
  height: 1158px;
  left: calc(50% - 1366px / 2);
  top: 1570px;
  background: #ebf8fb;
  box-shadow: 0px 41px 60px 11px rgba(161, 161, 161, 0.160047);
  border-radius: 20px;
  margin-top: 127px;
  height: fit-content;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 50px;
  }
`;

const AllWebinarsButton = styled.button`
  margin-top: 30px;
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  background: #ff6d00;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 72px;
`;

const HomePageWebinars = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityWebinar(limit: 3, sort: { fields: _createdAt, order: DESC }) {
        nodes {
          title
          subtitle
          slug {
            current
          }
          thumbnail {
            asset {
              url: gatsbyImageData(
                width: 614
                height: 321
                placeholder: NONE
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          thumbnailAlt
          excerpt {
            children {
              _key
              _type
              marks
              text
            }
            list
            style
            _type
            _rawChildren(resolveReferences: { maxDepth: 4 })
            _key
          }
          _createdAt
        }
      }
    }
  `);

  const [webinars, setWebinars] = useState(data?.allSanityWebinar?.nodes);

  useEffect(() => {
    if (data) {
      setWebinars(data?.allSanityWebinar?.nodes);
    }
  }, [data]);

  return (
    <MainContainer>
      <WebinarCard webinarProp={webinars?.[0]} separator={false} featured reverse={false} />
      <WebinarListContainer>
        <WebinarCard webinarProp={webinars?.[1]} separator={false} featured={false} reverse />
        <WebinarCard webinarProp={webinars?.[2]} separator={false} featured={false} reverse={false} />
      </WebinarListContainer>
      <ButtonContainer>
        <GatsbyLink to='/resources/webinars'>
          <AllWebinarsButton>
            All Webinars <FontAwesomeIcon icon={faArrowRight}> </FontAwesomeIcon>
          </AllWebinarsButton>
        </GatsbyLink>
      </ButtonContainer>
    </MainContainer>
  );
};

export default HomePageWebinars;
