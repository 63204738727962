import React, { useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import EBookCard from './EBookCard';

const EBooksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 30px;

  width: 100%;
  justify-content: center;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(2, auto);
  }

  @media (max-width: 965px) {
    display: flex;
    flex-direction: column;
  }
`;

const AllButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const AllButton = styled.button`
  min-width: 258px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  background: #ff6d00;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const EBookGrid = ({ pageView = false, currentEbookSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityEbook(limit: 3, sort: { fields: _createdAt, order: ASC }) {
        nodes {
          ebookImage {
            asset {
              url: gatsbyImageData(
                width: 250
                height: 321
                placeholder: NONE
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          ebookImageAlt
          excerpt {
            _key
            _rawChildren
            _type
            list
            style
            children {
              _key
              _type
              text
              marks
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);
  const [eBooks, setEBooks] = useState([]);

  useEffect(() => {
    setEBooks(data.allSanityEbook.nodes);
  }, [data]);

  const filterTwoEbooks = () => {
    const eBooksToShow = [];
    const availableEbooks = eBooks?.filter(ebook => ebook.slug.current !== currentEbookSlug);
    eBooksToShow.push(availableEbooks[0]);
    eBooksToShow.push(availableEbooks[1]);
    return eBooksToShow;
  };

  return (
    <>
      <EBooksContainer>
        {pageView
          ? filterTwoEbooks().map(eBook => <EBookCard eBookProp={eBook} pageView={pageView} />)
          : eBooks?.map(eBook => <EBookCard eBookProp={eBook} />)}
      </EBooksContainer>
      <AllButtonContainer>
        <Link to='/resources/ebooks'>
          <AllButton>
            All E-Books <FontAwesomeIcon icon={faArrowRight} />
          </AllButton>
        </Link>
      </AllButtonContainer>
    </>
  );
};

export default EBookGrid;
