import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroImage = () => {
  const ImageContainer = styled.div`
    max-width: 450px;

    @media (max-width: 830px) {
      width: 300px;
      height: 206.56px;
    }
  `;

  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/resources/heroImage.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  return (
    <ImageContainer>
      <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt='Hero HomePage Image' />
    </ImageContainer>
  );
};

export default HeroImage;
