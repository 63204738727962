import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box } from 'rebass';
import styled from 'styled-components';
import HomePageHero from '../../components/resources/HomePageHero';
import Layout from '../../components/layout/Layout';
import HomePageWebinars from '../../components/resources/HomePageWebinars';
import CaseStudyGrid from '../../components/resources/CaseStudyGrid';
import MetaData from '../../components/MetaData';
import ResourcesList from '../../sections/resources/ResourcesList';
import EBookGrid from '../../components/resources/EBookGrid';

const camelCase = require('camelcase');

const Title = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #202323;
  margin: 60px 0px;
`;

const ResourcesHomePage = () => {
  const { resources } = useStaticQuery(graphql`
    {
      resources: allFile(filter: { relativeDirectory: { eq: "images/resources" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const resourcesImages = {};
  resources.nodes.forEach(img => {
    resourcesImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Nexton Resources'
        description='Explore Nexton’s extensive collection of articles, guides, e-books, and more for all things tech and talent.'
        image={`https://www.nextonlabs.com${resourcesImages.homepageShareImage.images.fallback.src}`}
      />
      <Layout selected='resources'>
        <Box className='full-width'>
          <HomePageHero />
        </Box>
        <HomePageWebinars />
        <CaseStudyGrid buttonHidden={false} />
        <Title>E-books</Title>
        <Box className='full-width'>
          <EBookGrid />
        </Box>
        <Title>Talent Advice</Title>
        <Box className='full-width'>
          <ResourcesList resourcesImages={resourcesImages} />
        </Box>
      </Layout>
    </>
  );
};

export default ResourcesHomePage;
