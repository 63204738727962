import React from 'react';
import styled from 'styled-components';

const HeroText = () => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 830px) {
      width: 100%;
      font-weight: 300;
    }
  `;

  const HeroTitle = styled.h1`
    margin: 0px 100px 10px 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 68px;
    letter-spacing: -1px;
    color: #ffffff;

    @media (max-width: 1560px) {
      margin: 0px 50px 10px 10px;
    }

    @media (max-width: 1300px) {
      font-size: 42px;
      line-height: 60px;
    }

    @media (max-width: 830px) {
      text-align: center;
      font-weight: 300;
      font-size: 38px;
    }
  `;

  const HeroSubtitle = styled.h2`
    margin: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.5px;
    color: #ffffff;

    @media (max-width: 1300px) {
      font-size: 22px;
      line-height: 30px;
    }

    @media (max-width: 830px) {
      text-align: center;
      font-weight: 300;
      font-size: 20px;
      padding: 20px;
      line-height: 30px;
    }
  `;
  return (
    <Container>
      <HeroTitle>Empower Your Hiring Journey with Nexton Resources</HeroTitle>
      <HeroSubtitle>Access Valuable Tools, Guides, and Insights to Optimize Your Hiring Strategies</HeroSubtitle>
    </Container>
  );
};

export default HeroText;
