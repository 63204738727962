import React from 'react';
import styled from 'styled-components';
import HeroText from './HeroText';
import HeroImage from './HeroImage';

const HomePageHero = () => {
  const HeroContainer = styled.div`
    height: 640px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #00a5c8; /* For browsers that do not support gradients */
    background: linear-gradient(103.61deg, #00a5c8 55.72%, #9fe6f4 114.08%);
    box-shadow: 0px 31px 43px -14px rgba(161, 161, 161, 0.46);
    justify-content: center;
    align-items: center;

    @media (max-width: 830px) {
      flex-direction: column;
    }
  `;

  return (
    <HeroContainer>
      <HeroText />
      <HeroImage />
    </HeroContainer>
  );
};

export default HomePageHero;
